<template>
	<v-navigation-drawer
		id="navigation-drawer"
		v-model="localeValue"
		app
		dark
		:mobile-breakpoint="$vuetify.breakpoint.thresholds.md"
		:right="$i18n.locale === 'ar' || $vuetify.rtl"
		color="primary"
		:width="showCompanyValues || showCompanyHistory ? 215 : 200"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:mini-variant="$vuetify.breakpoint.mdAndUp && !localeValue"
		mini-variant-width="55"
	>
		<div class="d-flex flex-column justify-space-between" style="height: 100%">
			<div>
				<!-- brand image -->
				<router-link
					:to="{ name: 'dashboard' }"
					class="d-flex justify-center align-center mt-3"
				>
					<v-img
						src="../assets/logo-white.png"
						max-width="150"
						width="40"
						min-width="20"
						class="my-4"
						:class="{'mx-1': localeValue }"
					/>
				</router-link>
				<v-list
					nav
					:dense="pages.filter(page => page.show).length > Math.floor(($vuetify.breakpoint.height - (110 + 37)) / 56)"
				>
					<v-list-item
						v-for="(page, index) in pages.filter(page => page.show && !page.group).sort(sortPages)"
						:key="index"
						:to="typeof page.target === 'string' ? { name: page.target } : page.target"
						:disabled="page.disabled"
						exact-path
					>
						<v-list-item-icon>
							<v-icon>{{page.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-capitalize">
							{{page.title}}
						</v-list-item-title>
					</v-list-item>
				
					<v-list-group
						v-for="(group, index) in groupedPages"
						:key="group.title + index"
						:prepend-icon="group.icon"
						active-class="white--text"
					>
						<template v-slot:activator>
							<v-list-item-title class="text-capitalize">
								{{group.title}}
							</v-list-item-title>
						</template>

						<v-list-item
							v-for="(page, index) in group.pages"
							:key="index"
							:to="typeof page.target === 'string' ? { name: page.target } : page.target"
							:disabled="page.disabled"
							active-class="white--text"
							exact-path
						>
							<v-list-item-icon>
								<v-icon>{{page.icon}}</v-icon>
							</v-list-item-icon>
							<v-list-item-title class="text-capitalize">
								{{page.title}}
							</v-list-item-title>
						</v-list-item>
					</v-list-group>
				</v-list>
			</div>
			<div class="powered-by px-2 pb-1">
				<p class="mb-0">
					Midad 
					<span v-if="!localeValue || $vuetify.breakpoint.smAndDown">CMS</span>
					<span v-else>v{{version}}</span>
				</p>
				<p v-if="localeValue || $vuetify.breakpoint.smAndDown" class="mb-0">
					powered by
					<a target="_blank" href="https://www.beetronix.com">Beetronix</a>
				</p>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import * as routesConfigs from '@/configs/routes.config'
import { VERSION } from '@/helpers/constants';
import { customServices } from '@/configs/custom-pages.config';
import { mapState } from 'vuex';
import { customFilters } from '@/configs/custom-filters.config';

export default {
	name: 'NavigationDrawer',

	props: {
		value: { type: Boolean, default: false }
	},

	data: () => ({
		version: VERSION,

		// configs
		showSystemUsers: routesConfigs.systemUsers.show,
		showCompanyValues: routesConfigs.companyValues.show,
		showCompanyHistory: routesConfigs.companyHistories.show,
	}),

	computed: {
		...mapState({
			self: state => state.auth.self
		}),

		allowToShowMangeItems() {
			console.log(this.self);
			return this.self.hasPermission || !this.showSystemUsers;
		},

		groupedPages() {
			const groupsPages = [];

			const groups = [
				{ name: 'categorize', title: 'categorize', icon: 'mdi-shape' },
				{ name: 'tools', title: 'tools', icon: 'mdi-hammer-wrench' },
				{ name: 'about', title: 'about', icon: 'mdi-information-variant' },
			];

			groups.forEach(group => {
				const pages = this.pages.filter(c => c.show && c.group === group.name);
				if (pages.length > 0) {
					groupsPages.push({
						title: group.title,
						icon: group.icon,
						pages
					})
				}
			});

			return groupsPages;
		},

		pages() {
			return [
				{
					title: this.$t('NavigationDrawer.pages.dashboard'),
					target: routesConfigs.dashboard.name,
					icon: 'mdi-view-dashboard',
					disabled: false,
					show: routesConfigs.dashboard.show,
					orderWeight: 1,
				},
				{
					title: this.$t('NavigationDrawer.pages.file-manager'),
					target: routesConfigs.fileManager.name,
					icon: 'mdi-folder-cog-outline',
					disabled: false,
					show: routesConfigs.fileManager.show,
					group: 'tools'
				},
				{
					title: this.$t('NavigationDrawer.pages.sets'),
					target: routesConfigs.sets.name,
					icon: 'mdi-list-box-outline',
					disabled: false,
					show: routesConfigs.sets.show && this.allowToShowMangeItems,
					group: 'categorize'
				},
				{
					title: this.$t('NavigationDrawer.pages.categories'),
					target: routesConfigs.categories.name,
					icon: 'mdi-format-list-bulleted-square',
					disabled: false,
					show: routesConfigs.categories.show,
					group: 'categorize'
				},
				{
					title: this.$t('NavigationDrawer.pages.filters'),
					target: routesConfigs.filters.name,
					icon: 'mdi-filter-multiple',
					disabled: false,
					show: routesConfigs.filters.show && this.allowToShowMangeItems,
					group: 'categorize'
				},
				...customFilters.map(c => ({
					title: c.translates[this.$i18n.locale].plural,
					target: { name: c.route.name, params: { type: c.type } },
					icon: c.icon,
					disabled: false,
					show: this.allowToShowMangeItems,
					group: 'categorize'
				})),
				{
					title: this.$t('NavigationDrawer.pages.keywords'),
					target: routesConfigs.keywords.name,
					icon: 'mdi-tag-multiple',
					disabled: false,
					show: routesConfigs.keywords.show && this.allowToShowMangeItems,
					group: 'categorize'
				},
				{
					title: this.$t('NavigationDrawer.pages.publishers'),
					target: routesConfigs.publishers.name,
					icon: 'mdi-account-file-text',
					disabled: false,
					show: routesConfigs.publishers.show && this.allowToShowMangeItems,
					group: 'categorize'
				},
				{
					title: this.$t('NavigationDrawer.pages.products'),
					target: routesConfigs.products.name,
					icon: 'mdi-package-variant',
					disabled: false,
					show: routesConfigs.products.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.news'),
					target: routesConfigs.news.name,
					icon: 'mdi-newspaper',
					disabled: false,
					show: routesConfigs.news.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.services'),
					target: routesConfigs.services.name,
					icon: 'mdi-hexagon-multiple-outline',
					disabled: false,
					show: routesConfigs.services.show && this.allowToShowMangeItems,
				},
				...customServices.map(c => ({
					title: c.translates[this.$i18n.locale].plural,
					target: { name: c.mainPageRoute.name, params: { type: c.type } },
					icon: c.icon,
					disabled: false,
					show: this.allowToShowMangeItems,
				})),
				{
					title: this.$t('NavigationDrawer.pages.orders'),
					target: 'orders',
					icon: 'mdi-file-document-outline',
					disabled: false,
					show: routesConfigs.orders.show && this.allowToShowMangeItems,
					orderWeight: 1
				},
				{
					title: this.$t('NavigationDrawer.pages.plans'),
					target: 'plans',
					icon: 'mdi-palette-swatch-variant',
					disabled: false,
					show: routesConfigs.plans.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.coupons'),
					target: 'coupons',
					icon: 'mdi-ticket-percent',
					disabled: false,
					show: routesConfigs.coupons.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.offers'),
					target: 'offers',
					icon: 'mdi-brightness-percent',
					disabled: false,
					show: routesConfigs.offers.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.albums'),
					target: routesConfigs.albums.name,
					icon: 'mdi-image-album',
					disabled: false,
					show: routesConfigs.albums.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.videos'),
					target: routesConfigs.videos.name,
					icon: 'mdi-movie-open-play',
					disabled: false,
					show: routesConfigs.videos.show && this.allowToShowMangeItems,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.sliders'),
					target: routesConfigs.sliders.name,
					icon: 'mdi-palette-swatch',
					disabled: false,
					show: routesConfigs.sliders.show && this.allowToShowMangeItems,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.users'),
					target: routesConfigs.users.name,
					icon: 'mdi-account-group',
					disabled: false,
					show: routesConfigs.users.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.loyalty-points'),
					target: routesConfigs.loyaltyPoints.name,
					icon: 'mdi-progress-star-four-points',
					disabled: false,
					show: routesConfigs.loyaltyPoints.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.system-users-roles'),
					target: routesConfigs.systemUsersRoles.name,
					icon: 'mdi-account-cog',
					disabled: false,
					show: routesConfigs.systemUsersRoles.show && this.allowToShowMangeItems,
					group: 'categorize'
				},
				{
					title: this.$t('NavigationDrawer.pages.affiliate-marketers'),
					target: routesConfigs.affiliateMarketers.name,
					icon: 'mdi-account-file-text',
					disabled: false,
					show: routesConfigs.affiliateMarketers.show && this.allowToShowMangeItems
				},
				{
					title: this.$t('NavigationDrawer.pages.sellers'),
					target: routesConfigs.sellers.name,
					icon: 'mdi-account-tie-voice',
					disabled: false,
					show: routesConfigs.sellers.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.system-users'),
					target: routesConfigs.systemUsers.name,
					icon: 'mdi-account-supervisor',
					disabled: false,
					show: routesConfigs.systemUsers.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.branches'),
					target: routesConfigs.branches.name,
					icon: 'mdi-map-marker-radius',
					disabled: false,
					show: routesConfigs.branches.show,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.subscriptions'),
					target: routesConfigs.subscriptions.name,
					icon: 'mdi-email',
					disabled: false,
					show: routesConfigs.subscriptions.show && this.allowToShowMangeItems,
				},
				{
					title: this.$t('NavigationDrawer.pages.about-us'),
					target: routesConfigs.aboutUs.name,
					icon: 'mdi-information-outline',
					disabled: false,
					show: routesConfigs.aboutUs.show && this.allowToShowMangeItems,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.company-values'),
					target: routesConfigs.companyValues.name,
					icon: 'mdi-script-text',
					disabled: false,
					show: routesConfigs.companyValues.show && this.allowToShowMangeItems,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.company-histories'),
					target: routesConfigs.companyHistories.name,
					icon: 'mdi-timeline-clock-outline',
					disabled: false,
					show: routesConfigs.companyHistories.show && this.allowToShowMangeItems,
					group: 'about'
				},
				{
					title: this.$t('NavigationDrawer.pages.seo'),
					target: routesConfigs.SEO.name,
					icon: 'mdi-robot-happy-outline',
					disabled: false,
					show: routesConfigs.SEO.show && this.allowToShowMangeItems,
					group: 'tools'
				}
			]
		},

		localeValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		}
	},

	methods: {
		sortPages(a, b) {
			if ((a.orderWeight || 0) > (b.orderWeight || 0)) return -1;
			if ((a.orderWeight || 0) < (b.orderWeight || 0)) return 1;
			return 0;
		}
	}
}
</script>

<style lang="scss">
</style>